import { autorun, makeAutoObservable, observable, toJS } from 'mobx';

import { globalSocket } from 'socket/init';
import { switchTheme, THEMES_IDS } from 'themes';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import * as UtilsApi from 'store/models/api/Utils';
import { dialogCriteriaFormStore } from 'store/models/DialogCriteriaForm';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import setApiInterceptors from 'utils/apiIntereptors';
import {
    AGGR,
    APP_STORAGE_KEYS,
    FAST_INPUT,
    IS_APP,
    IS_IFRAME_CUSTOM_AUTH,
    IS_IFRAME_NEW_IDEA,
    IS_VOTING_BOARD,
    PLATFORM_CREATION_ID,
    PLATFORM_CREATION_YT,
    SERVICE_NAME,
    SHOW_TOOLTIPS,
    SLIM_BOARD,
    VIEWS,
} from 'utils/consts';
import delay from 'utils/delay';
import locationReload from 'utils/locationReload';
import logEvent from 'utils/logEvent';
import appStorage from 'utils/LsHelper';
import windowSize from 'utils/windowSize';

import { UpdateNotification } from 'components/AppToaster';

import { User } from './User';

const SCREEN_SIZE = {
    mobile: 810,
    tablet: 810,
    phone: 520,
};

const DEFAULT_SETTINGS = {
    [SHOW_TOOLTIPS]: true,
    [FAST_INPUT]: true,
    [SLIM_BOARD]: false,
    issueWidth: 500,
    issueVotingWidth: 350,
    issueHiddenTop: true,
    issueHiddenScore: true,
    issueHiddenAlignment: true,

    //issue final score Stripe
    alignmentOpen: false,
    alignmentSlim: false,

    // Members Insights
    aggr: AGGR.MONTH,

    //Matrix View - grid or diagram
    diagram: true,

    // Final score users
    limitUsers: false,

    /** @type {THEMES_IDS} */
    theme: IS_VOTING_BOARD ? THEMES_IDS.light : THEMES_IDS.dark,

    // criteria manager
    groupByCriteria: true,
    hideUnlinkedCriteria: true,
};

const pathRedirect = (function initRedirect() {
    if (!IS_APP) {
        return false;
    }
    const demoBoardPath = window.demo_board;
    return appStorage.get(APP_STORAGE_KEYS.board_init) || demoBoardPath;
})();

const { width } = windowSize();

export class UtilsStore {
    timezones = appStorage.get(APP_STORAGE_KEYS.timezones, []);
    notificationsSettings = window.subscriptions || [];
    userKey = window.userKey || null;
    settings = { ...DEFAULT_SETTINGS };
    blockedTableNav = false;
    staticUser = (window.user && new User(window.user)) || null;
    pathRedirect = pathRedirect;
    historyAction = appStorage.get(APP_STORAGE_KEYS.voting_action, IS_IFRAME_NEW_IDEA ? { create: null } : null);
    authDialog = null;
    isMobile = IS_VOTING_BOARD && width < SCREEN_SIZE.mobile;
    isTablet = IS_VOTING_BOARD && width < SCREEN_SIZE.tablet;
    isPhone = IS_VOTING_BOARD && width < SCREEN_SIZE.phone;
    forceLoader = false;
    forceYT = false;
    blocked = false;
    newVersion = false;
    showingTypeform = false;
    openMergeIdeas = false;
    openCriteriaBackup = false;
    openBoardsSearch = false;
    openTemplateOmnibar = false;
    openAnnounceHistory = false;
    openIdeaHistory = false;
    openBoardProgress = false;
    isFocusComment = false;
    fileUploading = false;
    isTrialBannerVisible = false;
    showOptIn = false;

    constructor() {
        appStorage.remove(APP_STORAGE_KEYS.voting_action);

        this.setSettings();

        this.forceLoader = this.pathRedirect && !!PLATFORM_CREATION_ID && !PLATFORM_CREATION_YT;
        this.forceYT = this.pathRedirect && !!PLATFORM_CREATION_YT;

        makeAutoObservable(this, { timezones: observable.ref });
    }

    setOpenTemplateOmnibar(open = false) {
        this.openTemplateOmnibar = open;
    }

    setOpenBoardsSearch(openBoardsSearch = false) {
        this.openBoardsSearch = openBoardsSearch;
    }

    clearUserActions() {
        this.historyAction = null;
        appStorage.remove(APP_STORAGE_KEYS.voting_action);
    }

    toggleFocusComment(state) {
        this.isFocusComment = state;
    }

    setSettings() {
        const settings = appStorage.get(APP_STORAGE_KEYS.config) || {};
        // Clear unused settings
        const clearSettings = Object.entries(settings).reduce((res, [key, value]) => {
            if (typeof DEFAULT_SETTINGS[key] !== 'undefined') {
                res[key] = value;
            }
            return res;
        }, {});
        this.settings = { ...DEFAULT_SETTINGS, ...clearSettings };
    }

    clearLoaders() {
        this.blocked = false;
        this.forceLoader = false;
    }

    setForceLoader(forceLoader) {
        this.forceLoader = forceLoader;
    }

    setForceYT(forceYT) {
        this.forceYT = forceYT;
    }

    newVersionAlert = () => {
        this.blocked = true;
        this.newVersion = true;
    };

    get isSlim() {
        return this.settings[SLIM_BOARD];
    }

    toggleSlimMenu() {
        this.updateSettings({ field: SLIM_BOARD, value: !this.isSlim });
    }

    updateSettings({ field, value }) {
        if (this.settings[field] === value) return;

        if (!['issueWidth', 'issueVotingField'].includes(field)) {
            logEvent('Change switcher setting', { field, value });
        }

        this.settings[field] = value;
    }

    fillSettings = (settings) => {
        logEvent('Change switcher setting', settings);
        Object.assign(this.settings, settings);
    };

    toggleTabNav(disableTableNav) {
        this.blockedTableNav = disableTableNav;
    }

    toggleVotingOptIn = (state) => {
        this.showOptIn = state || false;
    };

    get disableTableNav() {
        return settingsStore.isOpen || this.blockedTableNav;
    }

    clearRedirect() {
        appStorage.remove(APP_STORAGE_KEYS.board_init);
        window.demo_board = undefined;
        this.pathRedirect = undefined;
    }

    setOpenMergeIdeas(state = true) {
        this.openMergeIdeas = state;
        !!state && logEvent('Start merge');
    }

    setOpenCriteriaBackup(state = true) {
        this.openCriteriaBackup = state;
    }

    setOpenAnnounceHistory(state = true) {
        this.openAnnounceHistory = state;
    }

    setOpenIdeaHistory(state = true) {
        this.openIdeaHistory = state;
    }

    setOpenBoardProgress(state = true) {
        this.openBoardProgress = state;
    }

    get showAuth() {
        return this.authDialog;
    }

    set showAuth(status) {
        this.authDialog = status;
    }

    setHistoryAction(action) {
        utilsStore.historyAction = action;
        appStorage.set(APP_STORAGE_KEYS.voting_action, action);

        if (IS_IFRAME_CUSTOM_AUTH && window.parent) {
            window.parent.postMessage('showAuth', '*');
        } else {
            this.showAuth = true;
        }
    }

    clearHistoryActions() {
        this.historyAction = null;
    }

    setMobile = ({ width }) => {
        if (!IS_VOTING_BOARD) {
            return;
        }
        this.isMobile = width < SCREEN_SIZE.mobile;
        this.isTablet = width < SCREEN_SIZE.tablet;
        this.isPhone = width < SCREEN_SIZE.phone;
    };

    get cardIsHidden() {
        if (!issuesList.activeIssue?.id) {
            return true;
        }
        if ([VIEWS.TOP, VIEWS.REPORT].includes(mainStore.page)) {
            return this.settings.issueHiddenTop;
        } else if ([VIEWS.USERS_ALIGNMENT, VIEWS.CRITERIA_ALIGNMENT, VIEWS.TOTAL_ALIGNMENT].includes(mainStore.page)) {
            return utilsStore.settings.issueHiddenAlignment;
        }
        return this.settings.issueHiddenScore;
    }

    setTheme = (theme) => {
        logEvent('User set theme', { theme });
        this.updateSettings({ field: 'theme', value: +theme });
    };

    toggleCardHidden(value) {
        if (!this.cardIsHidden) {
            document.activeElement.blur();
        }
        if ([VIEWS.TOP, VIEWS.REPORT].includes(mainStore.page)) {
            this.updateSettings({
                field: 'issueHiddenTop',
                value: value === undefined ? !utilsStore.settings.issueHiddenTop : value,
            });
        } else if ([VIEWS.USERS_ALIGNMENT, VIEWS.CRITERIA_ALIGNMENT, VIEWS.TOTAL_ALIGNMENT].includes(mainStore.page)) {
            this.updateSettings({
                field: 'issueHiddenAlignment',
                value: value === undefined ? !utilsStore.settings.issueHiddenAlignment : value,
            });
        } else {
            this.updateSettings({
                field: 'issueHiddenScore',
                value: value === undefined ? !utilsStore.settings.issueHiddenScore : value,
            });
        }
    }

    toggleTypeform(status) {
        this.showingTypeform = status;
    }

    setTrialBanner(status) {
        this.isTrialBannerVisible = status;
    }

    get isDarkColor() {
        return [1, 3, 5].includes(this.settings.theme);
    }

    get isOpenedSideSettings() {
        return this.openMergeIdeas || this.openAnnounceHistory || this.openIdeaHistory || this.openBoardProgress;
    }

    closeSideSettings = () => {
        dialogCriteriaFormStore.criterion && dialogCriteriaFormStore.setCriterion(null);

        this.openMergeIdeas && (this.openMergeIdeas = false);
        this.openAnnounceHistory && (this.openAnnounceHistory = false);
        this.openIdeaHistory && (this.openIdeaHistory = false);
        this.openBoardProgress && (this.openBoardProgress = false);
        this.openCriteriaBackup && (this.openCriteriaBackup = false);
    };

    setFileUploading(state) {
        this.fileUploading = state;
    }

    // Api methods list

    pushNotificationsSettings = UtilsApi.pushNotificationsSettings;

    fetchNotificationsSettings = UtilsApi.fetchNotificationsSettings;

    fetchTimezones = UtilsApi.fetchTimezones;

    fetchTemplates = UtilsApi.fetchTemplates;
}

export const utilsStore = new UtilsStore();
setApiInterceptors(utilsStore);

autorun(() => {
    const settings = toJS(utilsStore.settings);
    appStorage.set(APP_STORAGE_KEYS.config, { ...settings });
});

if (!IS_VOTING_BOARD) {
    autorun(() => {
        const themeId = utilsStore.settings.theme;
        const theme = switchTheme(themeId);

        appStorage.set(APP_STORAGE_KEYS.user_colors, {
            bg1: theme.uiColors.background,
            primary11: theme.uiColors.color,
        });
    });
}

autorun(() => {
    if (utilsStore.newVersion) {
        const isNotVisiblePage = !document.hasFocus() && document.hidden;
        logEvent('A new version is available', { isNotVisiblePage });
        if (isNotVisiblePage) {
            delay(4000).then(() => locationReload());
            return;
        }

        UpdateNotification.show({
            message: `A new version of ${SERVICE_NAME} is available`,
            intent: 'primary',
            timeout: 0,
            action: {
                onClick: () => {
                    logEvent('Click - Refresh version');
                    locationReload();
                },
                text: 'Refresh',
            },
        });
    }
});

!IS_VOTING_BOARD && globalSocket.on('GlobalEvents.release', utilsStore.newVersionAlert);
