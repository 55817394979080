import groupBy from 'lodash/groupBy';
import { maxBy, minBy } from 'lodash/math';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import { makeAutoObservable, reaction, runInAction, when } from 'mobx';

import { CRITERION_TYPE, INITIAL_CRITERIA_STATE } from 'constants/Criterion';
import { getSocket } from 'socket/init';
import { sendToSentry } from 'utils';

import { MATRIX_SETTINGS_DEFAULT } from 'pages/Board/Matrix/constants';
import { RELEASE_NOTE_STATUS } from 'pages/Board/ReleaseNotes/constants';
import { KanbanFilter } from 'pages/Board/Voting/KanbanFilterModel';
import { getNextIndex } from 'pages/FocusMode';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { AutoSyncRule } from 'store/models/AutoSyncRule';
import columnsStore from 'store/models/Columns';
import criteriaStore from 'store/models/CriteriaStore';
import dictionaryStore from 'store/models/DictionaryStore';
import { PlatformSync } from 'store/models/PlatformSync';
import ReleaseNote from 'store/models/ReleaseNote';
import { orderUsersByMe } from 'store/models/utils/orderUsersByMe';
import { View } from 'store/models/View';

import {
    ALLOW_AI,
    APP_STORAGE_KEYS,
    CURRENT_ORG_ID,
    CURRENT_USER_ID,
    DICTIONARY_LABELS,
    DICTIONARY_LABELS_VOTING,
    DICTIONARY_STATUSES,
    DICTIONARY_STATUSES_VOTING,
    DICTIONARY_TYPES,
    EMPTY_ARRAY,
    FAKE_LIST,
    FINAL_SCORE_EDIT_VARIANTS,
    IS_IFRAME,
    IS_PUBLIC_BOARD,
    IS_VOTING_BOARD,
    PAYWALL_LIMITS,
    PLATFORM_FILTER_TYPE,
    PROVIDER_DUCALIS,
    SORT_DIRECTION,
    UNSAVED_MODEL_ID,
    USER_ROLE,
    VIEWS,
} from 'utils/consts';
import delay from 'utils/delay';
import logEvent from 'utils/logEvent';
import appStorage from 'utils/LsHelper';
import queryInText from 'utils/queryInText';

import { CustomIconName } from 'components/CustomIcon';
import {
    ASSIGNEE_COLUMN,
    COLUMNS,
    NAME_COLUMN,
    PREVIEW_DESC_COLUMN,
    PREVIEW_STATUS_COLUMN,
    PREVIEW_TYPE_COLUMN,
    RESUME_COLUMN,
    SKIP_COLUMN,
} from 'components/MasterTable/components/Columns';

import Announce from './Announce';
import * as BoardApi from './api/Board';
import { dialogCriteriaFormStore } from './DialogCriteriaForm';
import IssuesData from './IssuesData';
import { issuesList } from './IssuesList';
import { mainStore } from './MainStore';
import { UnvotedUsers } from './UnvotedUsers';
import { flatBoardHistory } from './utils/';
import { utilsStore } from './UtilsStore';

import { ORG_DB_STORES } from '../updateOrgDB';

const BOARD_DEFAULT_SORT_SETTINGS = {
    [VIEWS.TOP]: { id: 'row_number', direction: SORT_DIRECTION.asc, type: undefined },
    [VIEWS.EVALUATION]: null,
    [VIEWS.SCORES]: null,
    [VIEWS.USERS_ALIGNMENT]: { id: 'alignmentValue', direction: SORT_DIRECTION.asc, type: undefined },
    [VIEWS.CRITERIA_ALIGNMENT]: { id: 'alignmentValue', direction: SORT_DIRECTION.asc, type: undefined },
};

const DEFAULT_VIEWS = [VIEWS.TOP, VIEWS.EVALUATION, VIEWS.SCORES, VIEWS.USERS_ALIGNMENT, VIEWS.CRITERIA_ALIGNMENT];

export class Board {
    id = null;
    name = '';
    boardUsers = [];
    activeColumns = {};
    columnsIds = [];
    emoji = '';
    history = null;
    filter_data = null;
    provider = '';
    last_updated = '';
    notifications = null;
    settings = null;
    voting_settings = null;
    release_note_settings = null;
    slack_data = null;
    sync = null;
    top_priority = null;
    counts = null;
    owner_id = null;
    platform_id = null;
    _links = null;
    is_public = false;
    public_id = '';
    public_alias = '';
    voting_slack_data = '';
    description = '';
    votes_reset_schedule = null;
    backupData = null;
    searches = [];
    facilitators = new Set();
    needUpdate = false;
    sprint = null;
    voting_users_import_running = false;
    voting_users_csv_export = null;
    customFilterView = null;
    keep_demo_issues = false;
    unread_count = 0;
    allVotersCount = 0;
    form_tracker = null;
    remove_date = null;
    inviteSuggestions = [];

    query = '';
    filterLabels = new Set();

    changelogsId = new Map();

    // Dictionary
    ideaStatusTriggers = [];

    // Matrix
    matrixNonActiveCriteria = new Set();

    // csv
    importData = null;
    dialogImport = null;

    //Alert
    alertBox = null;

    //Issues
    filter = null;

    //Users with progress
    report = null;

    // Sync actions
    platformSync = null;

    showDemoRemover = false;
    showIdeaDemoRemover = false;

    openFilter = false;

    //loader
    isWaiting = false;

    // Loader notification
    isNotify = true;

    views = new Map();

    showSurveyForm = false;

    /* @type {null|KanbanFilter} */
    kanbanFilter = null;

    /* @type {boolean} */
    cannyLoader = false;

    socket;
    isBoard = true;

    selectedSyncFields = null;
    defaultSyncFields = null;

    constructor(data) {
        DEFAULT_VIEWS.forEach((view) => this.views.set(view, new View({ id: view })));
        this.fillModel(data);
        this.report = new UnvotedUsers(this.id);
        this.platformSync = new PlatformSync(this.id);

        this.fillSortSettings();

        this.showSurveyForm = appStorage.get(`${APP_STORAGE_KEYS.board_form}${data.id}`, false);

        if (IS_VOTING_BOARD) {
            this.kanbanFilter = new KanbanFilter(this);
        }

        try {
            makeAutoObservable(this, {
                isBoard: false,
                platformSync: false,
                report: false,
                socket: false,
                views: false,
                kanbanFilter: false,
            });
        } catch (e) {
            sendToSentry('Fail init Board', { id: this.id, error: e }, 'error');
        }

        reaction(
            () => this.filter?.companyFilter,
            () => this.filter?.getIds(),
        );

        if (!IS_VOTING_BOARD) {
            reaction(
                () => this.hasAccess,
                (hasAccess) => {
                    if (!hasAccess) {
                        this.socket?.disconnect();
                        logEvent('leave board > !hasAccess', { boardId: this.id });
                    }
                },
            );

            when(
                () => this.hasAccess,
                () => {
                    this.socket = IS_PUBLIC_BOARD ? mainStore.socket : getSocket(`/board:${this.public_id}`);

                    this.socket.on('IssueFactory.create', this.addSocketIssue.bind(this));
                    this.socket.on('IssueFactory.addToBoard', this.addSocketIssue.bind(this));
                    this.socket.on('IssueFactory.update', this.updateIssueFields.bind(this));

                    if (!IS_VOTING_BOARD) {
                        this.socket.on('IdeaAnnounceLabel.update', (data) => {
                            dictionaryStore.updateDictionaryItem('IdeaAnnounceLabel', data.item);
                        });
                        this.socket.on('IdeaStatus.update', (data) => {
                            dictionaryStore.updateDictionaryItem('IdeaStatus', data.item);
                        });

                        // RESET ALL
                        this.socket.on('IssueScoreModel.reset', this.resetIssuesScores.bind(this));
                        this.socket.on('IssueFactory.reset', this.resetIssues.bind(this));
                    }

                    if (!IS_PUBLIC_BOARD) {
                        this.socket.on('Board.update', this.updateModel.bind(this));

                        this.socket.on('ReleaseNote.create', this.createReleaseNoteFromSocket.bind(this));
                        this.socket.on('ReleaseNote.update', this.updateReleaseNoteFromSocket.bind(this));
                        this.socket.on('ReleaseNote.delete', this.removeReleaseNote.bind(this));

                        this.socket.on('VotingIssue.create', this.addBoardIdea);
                        this.socket.on('VotingIssue.update', this.updateBoardIdea);
                        this.socket.on('VotingIssue.vote', this.updateVotingVote.bind(this));
                        this.socket.on('VotingIssue.unvote', this.deleteVotingVote.bind(this));

                        this.socket.on('IssueScoreModel.update', this.updateIssueScore.bind(this));

                        // Announces
                        this.socket.on('VotingAnnounce.create', this.addNewAnnounce);
                        this.socket.on('VotingAnnounce.update', this.updateAnnounce);
                        this.socket.on('VotingAnnounce.delete', this.removeAnnounce);

                        this.socket.on('VotingIssue.reset', this.resetIdeas.bind(this));
                    }
                },
            );
        }
    }

    addBoardIdea = async (data) => {
        issuesList.updateIdea(data.item, true);
        await mainStore.db.updateRowDB(data.item, ORG_DB_STORES.ideas);
    };

    updateBoardIdea = async (data) => {
        issuesList.updateIdea(data.item);
        await mainStore.db.updateCurrentRowById(data.item, ORG_DB_STORES.ideas);
    };

    resetIssuesScores = () => {
        (IS_PUBLIC_BOARD && mainStore.fetchPublic()) || mainStore.fetchScores(this.id);
        logEvent('IssueFactory.reset');
    };

    resetIssues = () => {
        (IS_PUBLIC_BOARD && mainStore.fetchPublic()) || mainStore.updateIssues(this.id);
        logEvent('IssueScoreModel.reset');
    };

    resetIdeas = () => {
        mainStore.fetchBoardIdeas(this.id);
        logEvent('VotingIssue.reset');
    };

    addNewAnnounce = async (data) => {
        if (!data?.item) return;
        mainStore.announcesIds.set(data.item.id, new Announce(data.item));
        await mainStore.db.updateRowDB(data.item, ORG_DB_STORES.announces);
    };

    updateAnnounce = async (data) => {
        if (!data?.item) return;
        if (mainStore.announcesIds.has(data.item.id)) {
            const announceModel = mainStore.announcesIds.get(data.item.id);
            announceModel.updateModel(data.item);
        }
        await mainStore.db.updateCurrentRowById(data.item, ORG_DB_STORES.announces);
    };

    removeAnnounce = async (data) => {
        if (!data?.item || !mainStore.announcesIds.has(data.item.id)) return;
        mainStore.announcesIds.delete(data.item.id);
        await mainStore.db.removeRowDB(data.item.id, ORG_DB_STORES.announces);
    };

    async fillChangelogsId() {
        const list = await this.getReleaseNotesDb();

        runInAction(() => {
            list.forEach((item) => {
                const isMaster = item.is_master;
                const key = isMaster ? 'template' : item.id;
                this.changelogsId.set(key, new ReleaseNote(item));
            });
        });
    }

    updateIssueScore = (data) => {
        const key = `${data.item.id}-${this.id}`;
        const extraData = issuesList.issuesData.get(key);
        if (!extraData) {
            issuesList.issuesData.set(key, new IssuesData({ ...data.item, boardId: this.id }));
        } else {
            extraData?.fillModel(data.item);
        }
    };

    updateReleaseNoteFromSocket = async (data) => {
        const key = this.getReleaseNoteKey(data.item);

        if (this.changelogsId.has(key)) {
            const releaseNote = this.changelogsId.get(key);
            releaseNote.updateModel(data.item);
        }

        await mainStore.db.updateCurrentRowById(data.item, ORG_DB_STORES.releaseNotes);
    };

    createReleaseNoteFromSocket = async (data) => {
        this.updateReleaseNote(data.item);
        await mainStore.db.updateRowDB(data.item, ORG_DB_STORES.releaseNotes);
    };

    getReleaseNoteKey(note) {
        return note.is_master ? 'template' : note.id;
    }

    updateReleaseNote = (note) => {
        const key = this.getReleaseNoteKey(note);

        if (this.changelogsId.has(key)) {
            const releaseNote = this.changelogsId.get(key);
            releaseNote.updateModel(note);
        } else {
            this.changelogsId.set(key, new ReleaseNote(note));
        }
    };

    removeReleaseNote = async (data) => {
        this.changelogsId.delete(data.item.id);
        await mainStore.db.removeRowDB(data.item.id, 'releaseNotes');
    };

    updateVotingVote = (data) => {
        this.filter?.getIds();

        if (data.item.votingUserId !== mainStore.currentUser?.voting_user_id) return;

        mainStore.setVotingVote(data.item.id, data.item.vote);
    };

    deleteVotingVote = (data) => {
        this.filter?.getIds();

        if (data.item.votingUserId !== mainStore.currentUser?.voting_user_id) return;

        mainStore.deleteVotingVote(data.item.id);
    };

    setUserVote(vote, voting_issue_id, value) {
        const id = Number(voting_issue_id);
        if (mainStore.votingVotes.has(id)) {
            let count = mainStore.votingVotes.get(id);
            if (value >= 0) {
                count = value;
            } else {
                count += vote ? 1 : -1;
            }
            mainStore.setVotingVote(id, count);
        } else if (vote) {
            mainStore.setVotingVote(id, value ?? 1);
        }
    }

    setAllVotersCount(count = 0) {
        this.allVotersCount = count;
    }

    addSocketIssue = (data) => {
        const boardId = mainStore.getRealBoardIdByPublicId(data.boardId);
        issuesList.updateSingle(data.item, boardId);
        mainStore.db.updateRowDB({ ...data.item, boardId }, ORG_DB_STORES.issues);
    };

    updateIssueFields = async (data) => {
        const issueModel = this.issues.find((issue) => issue.id === data.item.id);
        issueModel?.updateModel(data.item);
        const issue = await mainStore.db.getData({ fieldName: ORG_DB_STORES.issues, query: [this.id, data.item.id] });
        if (issue) {
            await mainStore.db.updateRowDB({ ...issue, ...data.item }, ORG_DB_STORES.issues);
        }
    };

    fillVoteIssues() {
        this.activeIssues.forEach((issue) => {
            if (issue.externalData) issue.externalData.unvoted = !issue.fullVote;
        });
    }

    updateModel = async (data) => {
        this.fillModel(data.item);
        await mainStore.db.updateCurrentRowById(data.item, ORG_DB_STORES.boards);
    };

    get filterType() {
        if (this.provider === PROVIDER_DUCALIS) return PLATFORM_FILTER_TYPE.custom;
        if (!this.filter_data.is_custom) {
            return this.filter_data.internal_id ? PLATFORM_FILTER_TYPE.platform : PLATFORM_FILTER_TYPE.jql;
        }
        return PLATFORM_FILTER_TYPE.custom;
    }

    fillModel(data) {
        const { id, public_id, settings, active_columns, facilitators, ideaStatusTriggers, ...props } = data;

        if (!this.id) {
            this.id = id || public_id;
        }

        if (public_id && !this.public_id) {
            this.public_id = public_id;
        }

        Object.entries(props).forEach(([field, value]) => {
            if (typeof this[field] !== 'undefined') this[field] = value;
        });

        if (settings) {
            const dataMatrixSettings = data?.settings?.matrixSettings;
            const matrixSettings = { ...MATRIX_SETTINGS_DEFAULT, ...(dataMatrixSettings || {}) };
            this.settings = { ...data.settings, matrixSettings };
        }

        if (ideaStatusTriggers) {
            this.ideaStatusTriggers = orderBy(ideaStatusTriggers, 'id').map((el) => new AutoSyncRule(el, this));
        }

        if (facilitators) {
            if (this.facilitators.size) this.facilitators.clear();
            facilitators.forEach((id) => this.facilitators.add(id));
        }

        if (active_columns?.fields && Array.isArray(active_columns.fields)) {
            columnsStore.fillColumns(active_columns.fields);
            this.columnsIds = active_columns.fields.map((el) => el.field_id);
        }

        if (active_columns?.positions) {
            this.activeColumns = active_columns.positions;
        }
    }

    setName(name) {
        if (name !== this.name) {
            this.setProps({ name });
        }
    }

    updateVotingSettings(voting_settings) {
        Object.entries(voting_settings).forEach(([field, value]) => {
            this.voting_settings[field] = value;
        });
    }

    updateReleaseNoteSettings(settings) {
        Object.entries(settings).forEach(([field, value]) => {
            this.release_note_settings[field] = value;
        });
    }

    get criteriaIds() {
        return criteriaStore.criteriaByBoards[this.id] || EMPTY_ARRAY;
    }

    getBoardCriterionId(criterionId) {
        return `${this.id}_${criterionId}_${CURRENT_ORG_ID}`;
    }

    get criterions() {
        const list = this.criteriaIds.map((id) => criteriaStore.criteriaIds.get(id));

        return orderBy(
            list.filter((criterion) => criterion),
            (criterion) => {
                if (!criterion) return -Infinity;
                const id = this.getBoardCriterionId(criterion.id);
                return criteriaStore.criteriaBoardsIds.get(id)?.position;
            },
            'asc',
        );
    }

    get ideaLabels() {
        return dictionaryStore.ideaLabels.filter((el) => el.board_id === this.id);
    }

    get ideaStatuses() {
        return sortBy(
            dictionaryStore.ideaStatuses.filter((el) => el.board_id === this.id),
            'position',
        );
    }

    get apiEndpoint() {
        return `/boards/${this.id}`;
    }

    get activeCriteria() {
        return this.criterions;
    }

    get boardCriteria() {
        return this.criteriaIds.map((id) => criteriaStore.criteriaBoardsIds.get(this.getBoardCriterionId(id)));
    }

    get scoreTableCriteria() {
        if (!utilsStore.settings.limitUsers) return this.activeCriteria;
        if (this.currentUserCriteria.length > 0) return this.currentUserCriteria;
        return this.currentUserFacilitatorCriteria;
    }

    closeTypeForm = () => {
        this.showSurveyForm = false;
    };

    openSurveyForm = (data) => {
        this.showSurveyForm = data || true;
    };

    userCanChangeFinalScore(userId) {
        if (!this.currentUserInBoard && !mainStore.currentUser?.isAdmin) {
            return false;
        }

        const customVoteMode = this.settings.customVoteMode;

        if (customVoteMode === FINAL_SCORE_EDIT_VARIANTS.all) return true;
        if (customVoteMode === FINAL_SCORE_EDIT_VARIANTS.disabled) return false;

        return (
            FINAL_SCORE_EDIT_VARIANTS.facilitator !== customVoteMode ||
            (customVoteMode === FINAL_SCORE_EDIT_VARIANTS.facilitator && this.facilitators.has(userId))
        );
    }

    userCanChangeCriteriaFinalScore = (userId, criterionId) => {
        if (!this.currentUserInBoard && !mainStore.currentUser?.isAdmin) {
            return false;
        }

        const customVoteMode = this.settings.customVoteMode;

        if (customVoteMode === FINAL_SCORE_EDIT_VARIANTS.all) return true;
        if (customVoteMode === FINAL_SCORE_EDIT_VARIANTS.disabled) return false;

        if (this.facilitators.has(userId)) return true;
        const criterion = criteriaStore.criteriaBoardsIds.get(this.getBoardCriterionId(criterionId));
        if (!criterion || !Array.isArray(criterion.facilitators)) return false;
        return criterion.facilitators.includes(userId);
    };

    get criteriaWithFacilitators() {
        return this.criterions.filter((criterion) => criterion.facilitators?.length > 0);
    }

    get publicFullName() {
        return `${this.voting_settings?.emoji || ''} ${this.voting_settings?.name || ''}`;
    }

    get publicName() {
        return this.voting_settings?.name || this.name;
    }

    get publicEmoji() {
        return this.voting_settings?.emoji || this.name;
    }

    get namesForSearch() {
        if (this.isVoting && this.name !== this.publicName) {
            return `${this.name} ${this.publicName}`;
        }
        return this.name;
    }

    get ideaStatusesOrder() {
        return this.ideaStatuses.reduce((res, status) => {
            res[status.id] = status.position;
            return res;
        }, {});
    }

    getReleaseNotesDb = async () => {
        return mainStore.db.getListData({ fieldName: 'releaseNotes', index: 'boardId', query: this.id });
    };

    get changelogs() {
        if (mainStore.activeBoard?.id !== this.id) return [];

        const list = Array.from(this.changelogsId.values()).sort((a, b) => (b.created < a.created ? -1 : 1));
        return list.filter((note) => !note.is_master);
    }

    get releaseNoteTemplate() {
        return this.changelogsId.get('template');
    }

    get analytics() {
        if (!mainStore.ready) {
            return JSON.stringify(null);
        }
        return JSON.stringify({
            name: this.name,
            id: this.id,
            formula: this.settings.formula,
            slack: this.slack_data && this.slack_data.channel,
            share: this.isShared,
            access: this.is_public ? 'public' : 'private',
        });
    }

    get criteriaGroups() {
        return groupBy(
            this.criterions.filter((el) => ![CRITERION_TYPE.ignore, CRITERION_TYPE.multiplier].includes(el.type)),
            (criterion) => criterion.type,
        );
    }

    get hasAccess() {
        if ((this.isShared || this.isVoting) && this.id === this.public_id) {
            // For Shared boards
            return true;
        }
        if (this.is_public) {
            return true;
        }
        return this.boardUsers.includes(mainStore.currentUser?.id);
    }

    get isVisible() {
        if (IS_VOTING_BOARD) {
            return this.voting_settings?.dashboard_visible === true;
        }
        if (this.hasAccess) return true;
        return !!mainStore.currentUser?.isAdmin;
    }

    get votingVisibilityText() {
        const { is_private, restrict_access, dashboard_visible } = this.voting_settings;

        const postFix = !dashboard_visible ? ' by direct link' : '';
        if (is_private && restrict_access) {
            return `Only users from Voters List${postFix}`;
        }
        if (is_private) {
            return `Only sign in users${postFix}`;
        }

        return `Visible to Anyone${postFix}`;
    }

    get activeUsers() {
        return mainStore.users.orderedUsers.filter((el) => this.boardUsers.includes(el.id));
    }

    get activeUsersWithCriteria() {
        return mainStore.users.orderedUsers.filter((user) => this.usersIdsWithCriteria.has(user.id) && !user.isViewer);
    }

    get activeUsersWithCurrentUserCriteria() {
        const criteriaIds = this.currentUserCriteria.map(({ id }) => id);
        const usersIdsGroups = this.boardCriteria.map((boardCriterion) =>
            criteriaIds.includes(boardCriterion.criterion_id) ? boardCriterion.users : [],
        );
        const usersIds = new Set(usersIdsGroups.flat());
        return mainStore.users.orderedUsers.filter((user) => usersIds.has(user.id) && !user.isViewer);
    }

    get activeUsersByMe() {
        return orderUsersByMe(this.activeUsers);
    }

    get activeIssues() {
        return this.issues.filter((issue) => issue.status_id !== dictionaryStore.doneStatusId);
    }

    get orderedIssuesIds() {
        const orderedList = orderBy(
            this.activeIssues,
            (issue) => {
                if (!this.hideScores && typeof issue.externalData?.total === 'number') {
                    return issue.externalData?.total;
                }

                return -Infinity;
            },
            'desc',
        );

        return orderedList.map((el) => el.id).filter((id) => id !== UNSAVED_MODEL_ID);
    }

    /**
     * Current release note
     *
     * @return {ReleaseNote|null}
     */
    get releaseNote() {
        if (!mainStore.activeReleaseNoteId) return null;
        if (mainStore.activeReleaseNoteId === 'template') return this.releaseNoteTemplate;

        return this.changelogsId.get(+mainStore.activeReleaseNoteId) || null;
    }

    getIssueById(issueId) {
        return this.activeIssues.find((issue) => issue.id === issueId);
    }

    /**
     * Count issues without status Done
     *
     * @return {number}
     */
    get countActiveIssues() {
        return this.activeIssues.length;
    }

    get taskLoader() {
        return mainStore.boardsList.tasksBoards.has(this.id);
    }

    get canSendToPlatform() {
        return this.platform?.supportsExport;
    }

    get votingLockIcon() {
        const { is_private, restrict_access } = this.voting_settings;
        if (!is_private) {
            return CustomIconName.GLOBE;
        }
        if (is_private && restrict_access) {
            return CustomIconName.ID;
        }
        return CustomIconName.KEY;
    }

    /**
     * @return {[Issue]}
     */
    get issues() {
        return issuesList.allIssues.filter((issue) => issue.boardId === this.id);
        // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
        // runInAction(() => {
        //     list.forEach(
        //         (issue, index) => (issue.row_number = !hideScores && issue.id !== UNSAVED_MODEL_ID ? index + 1 : null)
        //     );
        //
        //     // const orderedList = orderBy(list, 'row_number');
        //     // sortIssuesByDependencies(orderedList, this.blockIssuesIds).forEach((issue, index) => {
        //     //     return (issue.blockRowNumber = index + 1);
        //     // });
        // });
        // return list;
    }

    userIsFacilitator(userId) {
        return this.facilitators.has(userId);
    }

    get hideScores() {
        if (this.userIsFacilitator(mainStore.currentUser?.id)) {
            return false;
        }
        return this.sprint?.hide_scores;
    }

    get disableEvaluation() {
        return this.sprint?.disable_evaluation;
    }

    get considerBlockers() {
        return this.settings?.considerBlockers || false;
    }

    get backlogCount() {
        return this.activeIssues?.length || 0;
    }

    get syncMapExists() {
        return !!this.sync.settings?.map_sync;
    }

    get topPriorityList() {
        if (!mainStore.readyFetchAll) return FAKE_LIST;
        if (!mainStore.page) return EMPTY_ARRAY;

        const page = [VIEWS.CRITERIA_ALIGNMENT, VIEWS.USERS_ALIGNMENT, VIEWS.TOP].includes(this.page)
            ? this.page
            : VIEWS.TOP;

        const view = this.views.get(page);

        if (!view) return EMPTY_ARRAY;

        const issues = this.isFilter ? this.issues : this.activeIssues;
        const sortedList = view.useSort(issues);
        const filteredList = this.useMainFilter(sortedList);

        if (
            !issuesList.activeIssue ||
            (issuesList.activeIssue.id !== UNSAVED_MODEL_ID &&
                !filteredList.map(({ id }) => id).includes(issuesList.activeIssue.id))
        ) {
            const row = issuesList.getInitialRow(filteredList.length);
            issuesList.setActiveIssue(filteredList[row]);
        }
        return filteredList;
    }

    get topPriorityListLength() {
        return this.topPriorityList.length;
    }

    get requestsList() {
        return this.activeIssues.filter((issue) => issue.hasOpenRequests);
    }

    get myQuestions() {
        const list = this.requestsList;
        return list.filter((issue) => issue.hasMyQuestion);
    }

    get questionsForMe() {
        return this.requestsList.filter((issue) => issue.hasQuestionForMe);
    }

    get anotherQuestions() {
        return this.requestsList.filter((issue) => issue.hasAnotherQuestions);
    }

    getListIssuesPrepared(issues) {
        if (issues.length === 0) return issues;

        // const view = this.views.get(mainStore.page);
        if (!this.view) return [];

        const list = this.view.useSort(issues, true);
        const filteredList = this.isFilter ? this.filter.useFilter(list) : list;
        if (
            !issuesList.activeIssue ||
            (issuesList.activeIssue.id !== UNSAVED_MODEL_ID &&
                !filteredList.map(({ id }) => id).includes(issuesList.activeIssue.id))
        ) {
            const row = issuesList.getInitialRow(filteredList.length);
            issuesList.setActiveIssue(filteredList[row]);
        }
        return filteredList;
    }

    getEvaluationIssues(view) {
        const criteria = this.currentUserCriteria;
        if (!criteria || criteria.length === 0) {
            return [];
        }
        if (!this.currentUserInBoard) {
            return [];
        }

        if (view === VIEWS.SCORES) {
            return this.activeIssues.filter(
                (issue) => !issue.unvoted && (this.query.length < 2 || queryInText(issue.fullName, this.query)),
            );
        }

        return this.activeIssues.filter(
            (issue) =>
                issue.unvoted &&
                !issue.hasMyQuestion &&
                (this.query.length < 2 || queryInText(issue.fullName, this.query)),
        );
    }

    get assessmentIssues() {
        const issues = this.allAssessmentIssues;
        return this.getListIssuesPrepared(issues);
    }

    get allAssessmentIssues() {
        return this.getEvaluationIssues(VIEWS.EVALUATION);
    }

    get allAssessmentIssuesVoted() {
        return this.getEvaluationIssues(VIEWS.SCORES);
    }

    get assessmentIssuesVoted() {
        const issues = this.allAssessmentIssuesVoted;
        return this.getListIssuesPrepared(issues);
    }

    get assessmentIssuesCount() {
        return this.allAssessmentIssues.length;
    }

    get allAssessmentIssuesVotedCount() {
        return this.allAssessmentIssuesVoted.length;
    }

    get allIdeas() {
        return issuesList.ideas.filter((idea) => idea.board_id === this.id && !idea.parent_id);
    }

    get mergedIdeas() {
        return issuesList.ideas.filter((idea) => idea.board_id === this.id && !!idea.parent_id);
    }

    get isNotFiltered() {
        return this.query.length <= 1 && !this.isFilter;
    }

    useMainFilter(list, isIdeas) {
        if (this.isNotFiltered) {
            return list;
        }
        let filterList;
        if (this.isFilter) {
            filterList = this.filter.useFilter(list);
        }

        if (this.query.length > 1) {
            filterList = (filterList || list).filter(({ fullName, name, announce }) =>
                isIdeas
                    ? queryInText(`${name} ${announce?.name || ''}`, this.query)
                    : queryInText(fullName, this.query),
            );
        }

        return filterList;
    }

    /**
     * Board Ideas with current filter and sort
     *
     * @returns {Idea[]}
     */
    get ideas() {
        return this.useMainFilter(this.allIdeas, true).sort((issueA, issueB) => issueB.votesCount - issueA.votesCount);
    }

    get internalGo() {
        return `${window.location.origin}/${this.public_alias}/go-internal`;
    }

    get maxValue() {
        return maxBy(this.activeIssues, (issue) => issue.value)?.value || 20;
    }

    get maxEffort() {
        return maxBy(this.activeIssues, (issue) => issue.effort)?.effort || 20;
    }

    get minValue() {
        return minBy(this.activeIssues, (issue) => issue.value)?.value || 0;
    }

    get minEffort() {
        return minBy(this.activeIssues, (issue) => issue.effort)?.effort || 0;
    }

    get midValue() {
        return (this.maxValue - this.minValue) / 2;
    }

    get midEffort() {
        return (this.maxEffort - this.minEffort) / 2;
    }

    get publicIssuesCount() {
        return this.issues.reduce((res, issue) => res + Number(issue.isPublic), 0);
    }

    get analyticsIssuesCount() {
        return this.activeIssues.reduce((res, issue) => res + Number(!issue.is_demo), 0);
    }

    get demoIssuesCount() {
        return this.activeIssues.length - this.analyticsIssuesCount;
    }

    get votingIssuesSingle() {
        const doneId = dictionaryStore.doneStatusId;
        return this.allIdeas.filter((issue) => !issue.issue_id && issue.status_id !== doneId);
    }

    getNextIssue(index) {
        const list = this.assessmentIssues;
        const newIndex = getNextIndex(index, list);
        issuesList.changeFocus({ row: index, col: 6, activeIssue: list[newIndex] });
    }

    get handlyIssuesVotedCount() {
        return this.activeIssues.length - this.countUnVoted;
    }

    get countVoted() {
        return this.voted.length;
    }

    get unVoted() {
        return this.activeIssues.filter((issue) => issue.isUserNotAllVoted && !issue.hasMyQuestion);
    }

    get voted() {
        return this.activeIssues.filter((issue) => !issue.unvoted);
    }

    get countUnVoted() {
        if (this.currentUserCriteria.length === 0 || mainStore.currentUser.isViewer) return 0;
        return this.unVoted.filter((el) => el.id !== UNSAVED_MODEL_ID).length;
    }

    get countQuestions() {
        return this.questionsForMe.length;
    }

    get countOnReview() {
        if (!this.isVoting) {
            return 0;
        }
        return this.votingIssuesSingle.length;
    }

    get needEvaluate() {
        if (!this.currentUserInBoard || !this.currentUserCriteria.length) return false;

        return issuesList.allIssues.some(
            (issue) =>
                issue.id !== UNSAVED_MODEL_ID &&
                issue.boardId === this.id &&
                (issue.provider !== PROVIDER_DUCALIS || issue.status_id !== dictionaryStore.doneStatusId) &&
                ((issue.isUserNotAllVoted && !issue.hasMyQuestion) || issue.hasQuestionForMe),
        );
    }

    get allCount() {
        if (!this.currentUserInBoard) {
            return null;
        }
        if (mainStore.currentUser?.isViewer || this.currentUserCriteria.length === 0) {
            return this.countQuestions;
        }
        return this.countUnVoted + this.countQuestions;
    }

    get owner() {
        return mainStore.users.usersIds.get(this.owner_id);
    }

    get currentUserIsOwner() {
        return CURRENT_USER_ID === this.owner_id;
    }

    get currentUserInBoard() {
        if (mainStore.currentUser?.isViewer) return false;
        return this.boardUsers.includes(mainStore.currentUser?.id);
    }

    get needNewOwner() {
        return (
            !this.owner ||
            (this.owner.platformIDs?.indexOf(this.platform?.id) === -1 && !this.isDucalis) ||
            !this.owner.isAdmin
        );
    }

    get fullName() {
        return `${this.emoji} ${this.name}`;
    }

    get adminsList() {
        return this.activeUsers
            .filter((user) => user.role === USER_ROLE.Admin)
            .map((user) => user.name)
            .join(', ');
    }

    get hasVotedIssues() {
        return this.activeIssues.some((issue) => issue.hasTotal);
    }

    get view() {
        return this.views.get(this.page);
    }

    /**
     * @param {IssuesFilter|null} filter
     */
    setFilter(filter) {
        this.filter = filter;
    }

    get hasFilter() {
        return this.filter !== null;
    }

    get isFilter() {
        return this.filter?.filter?.length > 0;
    }

    isProvider(provider) {
        return this.provider === provider;
    }

    /**
     * @return {Platform|null}
     */
    get platform() {
        const platform_id = this.platform_id;
        return (platform_id && mainStore.platformsList.getPlatformById(platform_id)) || null;
    }

    updatePropFields(objField) {
        Object.keys(objField).forEach((field) => {
            this[field] = objField[field];
        });
    }

    filterMatrix(criterion) {
        if (this.matrixNonActiveCriteria.has(criterion.id)) {
            this.matrixNonActiveCriteria.delete(criterion.id);
        } else {
            this.matrixNonActiveCriteria.add(criterion.id);
        }
        logEvent('Matrix Filter', { count: this.matrixNonActiveCriteria.size });
    }

    changeMatrixSettings({ position, name, emoji }) {
        logEvent('CHANGE_MATRIX_SETTINGS', { position, name, emoji });
        const matrixSettings = { ...this.settings.matrixSettings, [position]: { name, emoji } };
        this.setSettings({ matrixSettings });
    }

    setSort({ direction, field }) {
        const view = this.views.get(this.page) || new View({ id: VIEWS.TOP });
        const id = field.id || field.field;

        if (view.sortField === id && view.sortDirection === direction && view.sortFieldType === field.type) {
            view.setSort(BOARD_DEFAULT_SORT_SETTINGS[this.page]);
        } else {
            view.setSort({ ...field, direction });
        }

        view.setSortIds(null);

        this.saveSortSettings();
    }

    saveSortSettings() {
        const state = Object.keys(BOARD_DEFAULT_SORT_SETTINGS).reduce((res, view) => {
            res[view] = this.views.get(view).getSortState();
            return res;
        }, {});

        appStorage.set(`${APP_STORAGE_KEYS.board_sort}${this.id}`, state);
    }

    get page() {
        return mainStore.page === VIEWS.ALIGNMENT ? VIEWS.CRITERIA_ALIGNMENT : mainStore.page;
    }

    get historyMain() {
        const history = ((this.history && Object.keys(this.history)) || []).map((field) =>
            flatBoardHistory(this.history, field, this),
        );
        return orderBy(history.flat(), ['history.created'], ['desc']);
    }

    get syncStatus() {
        return this.sync?.settings?.connected || false;
    }

    get doneIdeaStatusId() {
        const status = this.ideaStatuses.find((el) => el.system_status === 'done');
        return (status && status.id) || null;
    }

    get doneIdeaStatusName() {
        const status = this.ideaStatuses.find((el) => el.system_status === 'done');
        return (status && status.name) || null;
    }

    get reviewIdeaStatusId() {
        const status = this.ideaStatuses.find((el) => el.system_status === 'under_review');
        return (status && status.id) || null;
    }

    get activeValues() {
        if (!this.criteriaGroups.value) return EMPTY_ARRAY;
        return this.criteriaGroups.value.filter((el) => !this.matrixNonActiveCriteria.has(el.id));
    }

    get activeEfforts() {
        if (!this.criteriaGroups.effort) return EMPTY_ARRAY;
        return this.criteriaGroups.effort.filter((el) => !this.matrixNonActiveCriteria.has(el.id));
    }

    get error() {
        return this.filter_data?.error || null;
    }

    get syncError() {
        return !mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.TWO_WAY_SYNC_BOARDS) ? null : this.sync?.error;
    }

    get issuesProviderName() {
        return this.filter_data?.name || this.name;
    }

    get criteriaEditableCell() {
        return this.currentUserCriteria.map((criterion, index) => ({
            id: `input-${criterion.id}`,
            name: criterion.name,
            criterion_id: criterion.id,
            type: 'input',
            fieldType: 'criterion',
            tabIndex: index + 1,
        }));
    }

    get evaluationCriteriaCount() {
        return this.criteriaEditableCell.length;
    }

    get publicLink() {
        return this._links.public;
    }

    get votingLink() {
        if (IS_VOTING_BOARD) {
            return `https://${window.location.hostname}/${this.public_alias}`;
        }
        return this._links.voting;
    }

    get language() {
        if (!IS_VOTING_BOARD) {
            return 'en';
        }
        return this.votingLanguage;
    }

    get votingLanguage() {
        return this.voting_settings?.language || mainStore.organization.language || 'en';
    }

    get basePath() {
        if (this.id === this.public_id) {
            if (IS_IFRAME) {
                return `/${this.public_id}`;
            }

            return this.public_alias && IS_VOTING_BOARD ? `/${this.public_alias}` : `/board/p/${this.public_id}`;
        }
        return `/board/${this.id}`;
    }

    get questionsPath() {
        return `${this.basePath}/questions`;
    }

    get mainPage() {
        if (IS_VOTING_BOARD) return this.basePath;
        return `${this.basePath}/${this.settings?.blocksOrder?.[0] ?? 'summary'}`;
    }

    get isShared() {
        return this.settings?.is_shared;
    }

    get isVoting() {
        return this.voting_settings?.enabled;
    }

    get isDucalis() {
        return this.provider === PROVIDER_DUCALIS;
    }

    get resetVotesByRole() {
        return this.settings.resetVotesByRole;
    }

    getCriteriaByUserId(userId) {
        if (!userId) {
            return [];
        }
        return this.criterions.filter((criterion) => {
            if (!criterion) return false;
            const id = this.getBoardCriterionId(criterion.id);
            const boardCriterion = criteriaStore.criteriaBoardsIds.get(id);
            return boardCriterion?.users?.includes(userId);
        });
    }

    get usersIdsWithCriteria() {
        const ids = this.boardCriteria.map((criterion) => criterion.users).flat();
        return new Set(ids);
    }

    get votedProgress() {
        return mainStore.votedPercents.get(this.id) ?? 0;
    }

    get currentUserCriteria() {
        return mainStore.currentUser?.id ? this.getCriteriaByUserId(mainStore.currentUser.id) : [];
    }

    get currentUserFacilitatorCriteria() {
        return this.criterions.filter((criterion) => criterion.facilitators?.includes(mainStore.currentUser.id));
    }

    getAllColumnsByGrid(grid) {
        const viewColumnsIds = this.getGridActiveColumnsList(grid);
        if (!viewColumnsIds) return [];
        return viewColumnsIds.map((columnId) => columnsStore.getColumnById(columnId));
    }

    /**
     * List ids columns
     *
     * @param {string} grid
     * @return {string[]}
     */
    getGridActiveColumnsList = (grid = VIEWS.TOP) => {
        return Object.values(this.activeColumns[grid] || {}).map((columnId) => String(columnId));
    };

    getColumnsByGrid(grid) {
        /**
         * List ids columns
         * @type {[String]}
         */
        const activeColumns = this.getGridActiveColumnsList(grid).filter((columnId) =>
            columnsStore.columns.has(columnId),
        );

        if (grid === VIEWS.TOP) activeColumns.unshift('row_number');

        return activeColumns.map(
            (columnId) =>
                COLUMNS.find((customColumn) => customColumn.id === columnId) || columnsStore.getColumnById(columnId),
        );
    }

    get topPriorityColumns() {
        const columns = this.getColumnsByGrid(VIEWS.TOP);

        if (IS_PUBLIC_BOARD) {
            return columns.filter((el) => !el.votingField);
        }
        return columns;
    }

    get scoreColumns() {
        const columns = this.getColumnsByGrid(VIEWS.EVALUATION);
        const criteriaFields = this.criteriaEditableCell;
        columns.push(...criteriaFields, RESUME_COLUMN);
        return columns;
    }

    get evaluationColumns() {
        const columns = this.getColumnsByGrid(VIEWS.EVALUATION);
        const criteriaFields = this.criteriaEditableCell;
        columns.push(...criteriaFields, SKIP_COLUMN);
        return columns;
    }

    get previewColumns() {
        return [NAME_COLUMN, PREVIEW_TYPE_COLUMN, PREVIEW_STATUS_COLUMN, ASSIGNEE_COLUMN];
    }

    get previewIdeasColumns() {
        return [NAME_COLUMN, PREVIEW_STATUS_COLUMN, PREVIEW_DESC_COLUMN];
    }

    get ideaTemplate() {
        if (this.voting_settings?.description_template_enabled) {
            return this.voting_settings.description_template;
        }
        return undefined;
    }

    get revealDateString() {
        return this.sprint?.reveal_at ? DateTime.fromISO(this.sprint.reveal_at).toFormat('DDD t') : '';
    }

    setOpenAddCriteria(type) {
        dialogCriteriaFormStore.setCriterion({ ...INITIAL_CRITERIA_STATE, type });
        settingsStore.goToLink(`/board/${this.id}/criteria`);
    }

    toggleUserNotificationsDialog = (path) => {
        logEvent(path ? 'Iframe: Open User Notifications settings' : 'Iframe: Close User Notifications settings');
    };

    toggleOpenFilter = () => {
        logEvent(!this.openFilter ? 'Open Board Filter' : 'Close Board Filter');

        this.openFilter = !this.openFilter;
    };

    clearImportFile() {
        this.dialogImport = false;
        this.activeIssue = null;
        this.importData = null;
    }

    getDictByName(dictionary) {
        switch (dictionary) {
            case DICTIONARY_TYPES:
                return dictionaryStore.issueTypes;
            case DICTIONARY_STATUSES:
                return dictionaryStore.issueStatuses;
            case DICTIONARY_LABELS:
                return dictionaryStore.issueLabels;
            case DICTIONARY_STATUSES_VOTING:
                return this.ideaStatuses;
            case DICTIONARY_LABELS_VOTING:
                return this.ideaLabels;
            default:
                return [];
        }
    }

    toggleDemoRemover(show = false) {
        this.showDemoRemover = show;
    }

    toggleIdeaDemoRemover(show = false) {
        this.showIdeaDemoRemover = show;
    }

    isBannerClosed(banner) {
        return (this.settings.closedNotes || []).includes(banner);
    }

    setWaiting(value = true) {
        this.isWaiting = value;
        !value && this.setBoardUpdateNotification(true);
        !value && this.getCustomFilterView();
    }

    setBoardUpdateNotification(value = true) {
        this.isNotify = value;
    }

    setQuery = (query) => {
        this.query = query;
    };

    setFilterLabels = (labelId) => {
        if (this.filterLabels.has(labelId)) {
            this.filterLabels.delete(labelId);
        } else {
            this.filterLabels.add(labelId);
        }
    };

    fillSortSettings() {
        const settings = appStorage.get(`${APP_STORAGE_KEYS.board_sort}${this.id}`, {});
        const sortSettings = { ...BOARD_DEFAULT_SORT_SETTINGS, ...settings };
        Object.entries(sortSettings).forEach(([view, props]) => {
            if (props) {
                const viewModel = this.views.get(view);
                viewModel.setSort({ ...props, id: props.id || props.field });
            }
        });
    }

    forceUpdate(status) {
        this.needUpdate = status;
        delay(100).then(() => (this.needUpdate = false));
    }

    toggleFacilitator = (userId) => {
        if (this.facilitators.has(userId)) {
            this.removeFacilitator(userId);
        } else {
            this.setFacilitator(userId);
        }
    };

    getFiltersByView(view) {
        const clearView = view === VIEWS.SCORES ? VIEWS.EVALUATION : view;
        return this.searches.filter((el) => el.grid === clearView);
    }

    fillSyncFields = (objFields) => {
        Object.assign(this, objFields);
    };

    get countScheduleChangelogs() {
        if (!this.isVoting) return 0;

        return this.changelogs.reduce((res, el) => res + Number(RELEASE_NOTE_STATUS.SCHEDULED === el.status), 0);
    }

    get columns() {
        return this.columnsIds.map((columnId) => columnsStore.getColumnById(columnId));
    }

    get showAlignment() {
        return mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.ALIGNMENT) && this.activeUsers.length > 1;
    }

    get isUserSubscribed() {
        if (!mainStore.currentUser?.id) {
            return false;
        }
        return mainStore.currentUser.board_subscriptions.includes(this.id);
    }

    get allowAI() {
        return (ALLOW_AI && this.voting_settings?.chatgpt_idea_creation) || false;
    }

    get allowAnnounceAI() {
        return (ALLOW_AI && this.voting_settings?.chatgpt_announce_creation) || false;
    }

    get kanbanGroups() {
        const group = groupBy(this.ideas, 'status_id');
        const doneStatusId = this.doneIdeaStatusId;
        group[doneStatusId] = orderBy(group[doneStatusId], 'dateUpdate', 'desc');

        return group;
    }

    get kanbanOrderedList() {
        const group = this.kanbanGroups;
        const statuses = orderBy(Object.keys(group), (statusId) => this.ideaStatusesOrder[statusId] || 1000);

        return statuses
            .map((status) => group[status])
            .flat()
            .filter((el) => el);
    }

    toggleVotingStatus = async (e) => {
        const settings = { enabled: e.target.checked };
        logEvent('Voting Board', settings);
        await this.setVotingSettings(settings);
    };

    // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
    // get blockIssuesIds() {
    //     const list = Array.from(mainStore.linkedIssues.values()).filter((el) => {
    //         return (
    //             el.type === 'block' &&
    //             el.board_id === this.id &&
    //             el.linked_board_id === this.id &&
    //             !el.linked_issue_completed
    //         );
    //     });
    //     const groups = groupBy(list, (link) => `${link.issue_id}-${link.board_id}`);
    //     const blocksIssues = new Map();
    //
    //     Object.entries(groups).forEach(([uid, list]) => {
    //         const clearList = list.map(({ linked_issue_id }) => linked_issue_id);
    //         blocksIssues.set(uid, clearList);
    //     });
    //
    //     return blocksIssues;
    // }

    // API methods

    remove = BoardApi.remove;

    setProps = BoardApi.setProps;

    setSettings = BoardApi.setSettings;

    setFacilitator = BoardApi.setFacilitator;

    removeFacilitator = BoardApi.removeFacilitator;

    setVotingSettings = BoardApi.setVotingSettings;

    setReleaseNoteSettings = BoardApi.setReleaseNoteSettings;

    sortCriterion = BoardApi.sortCriterion;

    setCriterion = BoardApi.setCriterion;

    removeCriterion = BoardApi.removeCriterion;

    removeSlack = BoardApi.removeSlack;

    importFile = BoardApi.importFile;

    updateImportFields = BoardApi.updateImportFields;

    updateDictItem = BoardApi.updateDictItem;

    createDictItem = BoardApi.createDictItem;

    removeDictItem = BoardApi.removeDictItem;

    removeDemoIssues = BoardApi.removeDemoIssues;

    removeDemoIdeas = BoardApi.removeDemoIdeas;

    sortColumn = BoardApi.sortColumn;

    sortStatuses = BoardApi.sortStatuses;

    setColumn = BoardApi.setColumn;

    closeBanner = BoardApi.closeBanner;

    fetchBackupData = BoardApi.fetchBackupData;

    saveFilter = BoardApi.saveFilter;

    deleteFilter = BoardApi.deleteFilter;

    setSyncSettings = BoardApi.setSyncSettings;

    uploadVotingCSV = BoardApi.uploadVotingCSV;

    getAllVotersCount = BoardApi.getAllVotersCount;

    createVotingEmails = BoardApi.createVotingEmails;

    deleteVotingEmails = BoardApi.deleteVotingEmails;

    setSyncFields = BoardApi.setSyncFields;

    setIdeaStatusTriggers = BoardApi.setIdeaStatusTriggers;

    updateIdeaStatusTriggers = BoardApi.updateIdeaStatusTriggers;

    deleteIdeaStatusTriggers = BoardApi.deleteIdeaStatusTriggers;

    countIdeaStatusTriggers = BoardApi.countIdeaStatusTriggers;

    exportVotersCsv = BoardApi.exportVotersCsv;

    fetchBoard = BoardApi.fetchBoard;

    createReleaseNoteTemplate = BoardApi.createReleaseNoteTemplate;

    duplicateReleaseNote = BoardApi.duplicateReleaseNote;

    toggleSubscriptionVotingBoard = BoardApi.toggleSubscriptionVotingBoard;

    getCustomFilterView = BoardApi.getCustomFilterView;

    getReleaseNotesLabels = BoardApi.getReleaseNotesLabels;

    getReleaseNotes = BoardApi.getReleaseNotes;

    getUnreadCount = BoardApi.getUnreadCount;

    addUserToCriterion = BoardApi.addUserToCriterion;

    removeUserFromCriterion = BoardApi.removeUserFromCriterion;

    addCriterionToBoard = BoardApi.addCriterionToBoard;

    addUserToBoard = BoardApi.addUserToBoard;

    removeUserFromBoard = BoardApi.removeUserFromBoard;

    addFrameworkToBoard = BoardApi.addFrameworkToBoard;

    getCannyBoards = BoardApi.getCannyBoards;

    runCannyImport = BoardApi.runCannyImport;

    suggestVoters = BoardApi.suggestVoters;

    suggestIssue = BoardApi.suggestIssue;

    syncFieldUpdate = BoardApi.syncFieldUpdate;

    getBoardHistory = BoardApi.getBoardHistory;

    resetScores = BoardApi.resetScores;

    getInviteSuggestions = BoardApi.getInviteSuggestions;

    changeIdeaSystemStatus = BoardApi.changeIdeaSystemStatus;

    getSyncFields = BoardApi.getSyncFields;

    getAvailableSyncFields = BoardApi.getAvailableSyncFields;

    getColumns = BoardApi.getColumns;
}
